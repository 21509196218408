import request from "@/utils/request";

export function getMyProcessInstancePage(data) {
  return request({
    url: "bpm/process-instance/my-page",
    method: "post",
    data,
  });
}

export function createProcessInstance(data) {
  return request({
    url: "/bpm/process-instance/create",
    method: "post",
    data: data,
  });
}

export function cancelProcessInstance(id, reason) {
  return request({
    url: "/bpm/process-instance/cancel",
    method: "DELETE",
    data: {
      id,
      reason,
    },
  });
}

export function getProcessInstance(id, taskId) {
  return request({
    url: "/bpm/process-instance/get",
    method: "get",
    params: {
      id,
      taskId ,
    },
  });
}
//查询条件字段
export function getExpression(modelKey) {
  return request({
    url: "/bpm/flowManagement/expression/field",
    method: "get",
    params: {
      modelKey
    },
  });
}

// 我发起的流程列表
export function getSimpletaskinitiatepage(params) {
  return request({
    url: "/bpm/simple/task/initiate-page",
    method: "get",
    params,
  });
}

// 新建流程任务
export function simpleTaskCreate(data) {
  return request({
    url: "/bpm/simple/task/create",
    method: "post",
    data,
  });
}

// 新建流程任务
export function simpleTaskInfo(params) {
  return request({
    url: "/bpm/simple/task/taskInfo",
    method: "get",
    params,
  });
}

// 撤销流程
export function cancelSimpleTaskRevoke(id, reason) {
  return request({
    url: "/bpm/simple/task/revoke",
    method: "put",
    data: {
      id,
      reason,
    },
  });
}

// 草稿列表
export function getFlowDraft(params) {
  return request({
    url: "/bpm/process-instance/getFlowDraft",
    method: "get",
    params,
  });
}

// 添加草稿
export function createFlowDraft(data) {
  return request({
    url: "/bpm/process-instance/createFlowDraft",
    method: "post",
    data,
  });
}

// 修改草稿
export function updateFlowDraft(data) {
  return request({
    url: "/bpm/process-instance/updateFlowDraft",
    method: "post",
    data,
  });
}

// 查看草稿
export function getFlowDraftById(params) {
  return request({
    url: "/bpm/process-instance/getFlowDraftById",
    method: "get",
    params,
  });
}

// 删除草稿
export function deleteFlowDraft(params) {
  return request({
    url: "/bpm/process-instance/deleteFlowDraft",
    method: "delete",
    params,
  });
}
