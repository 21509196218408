
import { getInfo, getUserToken } from "@/api/login";
import { Message, Loading } from 'element-ui';
import store from '@/store';
// 存储token和用户信息
async function storeTokenAndUserInfo(token) {
    localStorage.setItem('key_user_token', JSON.stringify(token));
    const { code, data, msg } = await getInfo();
    if (code == 0) {
        localStorage.setItem('store-user-info', JSON.stringify({ userInfoState: data }));
        store.commit('imWorkbench/setUserId', localStorage.getItem("store-user-info") ? JSON.parse(localStorage.getItem("store-user-info")).userInfoState.userId : "")
    } else {
        Message.error(msg);
    }
}


export default (router) => {
    // 全局前置守卫
    router.beforeEach(async (to, from, next) => {
        let loadingInstance = '';
        if (to.query.uuid) {
            loadingInstance = Loading.service({ text: '正在登录...' });
            window.isH5 = true;
            const { data } = await getUserToken(to.query.uuid);
            const token = 'Bearer ' + data.accessToken
            await storeTokenAndUserInfo(token);
        }
        if (to.query.token) {
            await storeTokenAndUserInfo(to.query.token);
        }
        setTimeout(() => {
            if (loadingInstance) {
                loadingInstance.close();
            }
            next(); // 继续导航
        }, window.isH5 ? 2000 : 0);
    })

    return router
}