<template>
  <div>
    <el-dialog title="审批流程" :close-on-click-modal="false" :visible.sync="dialogVisible" :width="width"
      v-if="dialogVisible" :modal-append-to-body="false" :custom-class="'evaluate-dialog'">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" @close="close"
        label-position="top">
        <p v-if="message == 1" style="font-size: 18px;"><i class="el-icon-success"
            style="margin-right: 10px;color: #67C23A;"></i>您已成功提交该表单，是否需通过该审批？</p>
        <!-- <el-form-item label="" prop="reason">
          <div v-if="!pass" class="required-ioc">*</div>
          <el-input type="textarea" v-model="ruleForm.reason" placeholder="请填写审批建议"
            @keydown.native="listen($event)"></el-input>
        </el-form-item> -->
        <div class="inputArea" :contenteditable="true" ref="inputArea" @keydown="handleKeyDown"></div>
      </el-form>
      <div class="tag">
        <p style="margin-bottom: 8px">推荐回复</p>
        <div class="tag_reply">
          <div class="tag_list">
            <el-tag style="cursor: pointer" @click="chooseTag(item)" v-for="(item, index) in tagList" :key="index">{{
              item
              }}</el-tag>
            <div class="tag_add" v-for="item in CommentList" :key="item.id" @click="chooseTag(item.tagName)"
              :class="{ active: !isDelete, die: isDelete }">
              {{ item.tagName }}
              <img src="@/assets/ioc/minus-circle.png" alt="" @click.stop="deleteComment(item.id)" v-if="isDelete">
            </div>

          </div>
          <div class="tag_operate">
            <img src="@/assets/ioc/plus-add.png" alt="" @click="addComment = true" v-if="!isDelete">
            <img src="@/assets/ioc/minus-circle.png" alt="" @click="isDelete = true" v-if="!isDelete">
            <img src="@/assets/ioc/times-circle.png" alt="" @click="isDelete = false" v-if="isDelete">
          </div>
        </div>
        <!-- <div class="tag_list">
        <el-tag style="cursor: pointer" @click="chooseTag(item)" v-for="(item, index) in tagList" :key="index">{{ item
          }}</el-tag>
      </div> -->
      </div>
      <div class="file">
        <div>
          <el-upload :action="uploadAction" :show-file-list="false" :data="uploadData" :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 1)
            " multiple :before-upload="(file) => beforeAvatarUpload(file, 1)">
            <i class="el-icon-picture-outline"></i>
            <span>图片</span>
          </el-upload>
        </div>
        <div>
          <el-upload :action="uploadAction" :show-file-list="false" :data="uploadData" multiple :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 2)
            " :before-upload="(file) => beforeAvatarUpload(file, 2)">
            <i class="el-icon-link"></i>
            <span>附件</span>
          </el-upload>
        </div>
      </div>

      <div class="picture_list">
        <div v-for="(item, index) in ruleForm.picture" :key="index">
          <el-image style="width: 50px; height: 50px" :src="item.url" :preview-src-list="previewList">
          </el-image>
          <div class="del_box">
            <i class="el-icon-close" style="cursor: pointer; color: #fff" @click="delPicture(index)"></i>
          </div>
        </div>
      </div>
      <div class="attachment_list">
        <div v-for="(item, index) in ruleForm.attachment" :key="index"
          :style="{ borderTop: index == 0 ? '1px solid #c9c9c9' : 'none', }">
          <div>
            <i class="el-icon-document" style="font-size: 28px;"></i>
            <p>{{ item.name }}</p>
          </div>
          <div>
            <el-link @click="preview(item)">预览</el-link><i class="el-icon-delete" style="cursor: pointer"
              @click="delAttachment(index)"></i>
          </div>
        </div>
      </div>


      <div style="display: flex;height: 20px;align-items: center;">
        <div style="width: 16px;height: 16px;margin-right: 4px;">
          <img src="@/assets/ioc/check4.png" alt="" style="width: 100%;height: 100%;cursor: pointer;" v-if="isNotice"
            @click="isNotice = false">
          <img src="@/assets/ioc/check3.png" alt="" style="width: 100%;height: 100%;cursor: pointer;" v-else
            @click="isNotice = true">
        </div>
        <div style="height: 20px;line-height: 20px;" class="aaaaaa"><span>审批意见通过聊天转发给</span><span class="sss">{{
            processInstance.startUser.userName
            }}</span><span class="sss" v-for="item in atList" :key="item.userId">{{ item.userName }}</span></div>
      </div>

      <span slot="footer" class="dialog-footer">
        <div @click="dialogVisible = false" class="cancel">
          取消
        </div>
        <div @click="fangd('ruleForm')" class="submit">
          确定
        </div>
      </span>
    </el-dialog>


    <el-dialog title="新增回复" :modal-append-to-body="false" :visible.sync="addComment" width="30%" @close="reply = ''"
      :custom-class="'evaluate-dialog'">
      <el-input v-model="reply" placeholder="请输入"></el-input>
      <span slot="footer" class="dialog-footer">
        <div @click="debouncedAddReply" class="submit" style="width: 100%;margin-left: 0px;">
          确定
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { debounce, times } from 'lodash-es';
  import { approveTask, rejectTask, flowUrge } from "@/api/bpm/task";
  import { addTags, getTagsList, deleteTags } from "@/api/bpm/flow";
  import { switchDigest, atUserList, createAtDom } from '@/utils/switchdigest'
  import { uploadURLFromRegionCode, init, config } from "@/utils/qiniuUploader";
  // import { getType } from "@/utils"
  export default {
    props: {
      taskId: {
        type: String,
        default: null,
      },
      processInstance: {
        type: Object,
        default: () => { },
      },
      detailForm: {
        type: Object,
        default: () => { },
      },
      candidate_dept: {
        type: [String, Number],
        default: null,
      },
      width: {
        type: String,
        default: '30%'
      },
      nodesInfo: {
        type: Object,
        default: () => { },
      },
    },
    watch: {
      pass(val) {
        this.rules.reason[0].required = !val;
      },
    },
    async created() {
      // 初始化防抖函数，并确保其可重复使用
      this.debouncedSubmit = debounce(this.submitForm, 500);
      this.debouncedAddReply = debounce(this.addReply, 500);
      this.getuptoken();
      await this.getCommentList();
    },
    mounted() {
      window.electron?.atUserListOn((e, i) => {
        if (this.$refs.inputArea) {
          atUserList(i).then(res => {
            let arr = res.atList.slice(0, 4)
            if (this.atList.length < 4) {
              this.atList = [...this.atList, ...arr].slice(0, 4)
            }
            res.arrList.forEach(e => {
              let dom = createAtDom(e)
              this.$refs.inputArea.appendChild(dom)
            })
            //将输入光标定位到输入框的末尾
            this.$nextTick(() => {
              const inputArea = this.$refs.inputArea;
              const range = document.createRange();
              const selection = window.getSelection();
              range.setStart(inputArea, inputArea.childNodes.length);
              range.collapse(true);
              selection.removeAllRanges();
              selection.addRange(range);
              inputArea.focus();
            });
          })

        }

      })
    },
    computed: {
      tagList() {
        let arr1 = [
          "同意",
          "确认",
          "ok",
          "情况属实",
          "已审核",
          "好的",
          "通过",
          "已核实",
          "可以",
        ];
        let arr2 = [
          "拒绝",
          "不同意",
          "取消",
          "作废",
          "重复提交",
          "未解决",
          "请核实",
          "请补充材料",
        ];
        return this.pass ? arr1 : arr2;
      },
    },
    data() {
      return {
        debouncedAddReply: null,  // 初始化为 null
        debouncedSubmit: null,
        loading: false,
        dialogVisible: false,
        pass: true,
        uploadAction: "", //图片上传
        uploadData: {}, //上传参数
        ruleForm: {
          reason: "",
          picture: [],
          attachment: [],
        },
        rules: {
          reason: [{ required: false, message: "请填写备注", trigger: "change" }],
        },
        message: 0,
        addComment: false,
        reply: "",
        CommentList: [],
        isDelete: false,
        digestIf: 0,
        digestInfo: "",
        formFields: [],
        confFields: [],
        atList: [],
        isNotice: false
      };
    },
    methods: {
      handleKeyDown(event) {
        console.log(`output->1111`, 1111)
        if (event.key === 'Backspace') {

          const selection = window.getSelection();
          const range = selection.getRangeAt(0);
          const startContainer = range.startContainer;
          let element = startContainer;

          if (element && element.tagName === 'DIV' && element.childNodes.length > 0) {
            const startOffset = range.startOffset;

            // 阻止默认的删除行为
            // console.log(`output->prevSibling`, element.childNodes,startOffset)

            if (element.childNodes.length > (startOffset - 1)) {
              if (element.childNodes[startOffset - 1].tagName == 'SPAN') {
                event.preventDefault();
                console.log(`output->`, element.childNodes[startOffset - 1].getAttribute('data-id'))
                if (element.childNodes[startOffset - 1].getAttribute('data-id')) {
                  let index = element.childNodes[startOffset - 1].getAttribute('data-id')
                  // console.log(`output->this.atList`,this.atList)
                  this.atList = this.atList.filter(item => item.userId != index);
                }
                element.childNodes[startOffset - 1].remove()
              }
            }
          }
        }
        if (event.shiftKey && event.which === 50 && window.ipcRenderer) {
          console.log(`output->@了！！！！`)
          event.preventDefault();
          this.openUserDialog()
        }
      },

      //获取评论回复列表
      async getCommentList() {
        const data = await getTagsList(this.$store.state.imWorkbench.userId);
        this.CommentList = data?.data;
      },
      //删除回复
      async deleteComment(id) {
        const data = await deleteTags(id);
        this.$modal.msgSuccess("删除成功");
        await this.getCommentList();
      },
      // 添加回复
      async addReply() {
        if (this.reply) {
          const data = {
            tagName: this.reply,
            userId: this.$store.state.imWorkbench.userId,
          }
          await addTags(data);
          this.$modal.msgSuccess("添加成功");
        }
        await this.getCommentList();
        this.addComment = false;
      },

      chooseTag(text) {
        // console.log(`output->123`,this.$refs.inputArea)
        // this.ruleForm.reason = text;
        let textNode = document.createTextNode(text);
        this.$refs.inputArea.appendChild(textNode);
      },
      close() {
        this.ruleForm.reason = "";
        this.message = 0
        this.dialogVisible = false
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.$refs.inputArea.textContent.trim() == '' && !this.pass) {
              this.$modal.msgError("请输入审批意见")
              return
            }
            this.handleAudit();
          } else {
            //   console.log("error submit!!");
            return false;
          }
        });
      },
      // 获取七牛云token
      getuptoken() {
        this.uptoken = this.$store.state.imWorkbench.UploadToken.upToken
        this.initQiniu(this.uptoken);
      },

      initQiniu(uptoken) {
        var options = {
          // bucket所在区域，这里是华北区。ECN, SCN, NCN, NA, ASG，分别对应七牛云的：华东，华南，华北，北美，新加坡 5 个区域
          region: "SCN",
          uptoken: this.uptoken,
          uptokenURL: "https://[yourserver.com]/api/uptoken",
          uptokenFunc: function () { },
          domain: "https://source.fungsong.com",
          shouldUseQiniuFileName: false,
        };
        // 将七牛云相关配置初始化进本sdk
        init(options);
        this.uploadAction = uploadURLFromRegionCode(config.qiniuRegion);
        this.uploadUrl = uploadURLFromRegionCode(config.qiniuRegion);
        this.uploadData = {
          token: uptoken,
        };
      },
      // 上传
      handleSuccess(res, file, fileList, type) {
        // type 1 图片  2附件
        let dataObject = res;
        this.fileList = fileList;
        let fileURL = config.qiniuBucketURLPrefix + "/" + dataObject.key;
        const data = {};
        data.url = fileURL;
        data.name = file.name;
        this.imageUrl = data;
        if (type == 1) {
          this.ruleForm.picture.push(data);
          this.previewList.push(fileURL);
        } else {
          this.ruleForm.attachment.push(data);
        }
      },

      beforeAvatarUpload(file, type) {
        // type 1 图片  2附件
        var img = file.name.substring(file.name.lastIndexOf(".") + 1);
        const suffix = img === "jpg";
        const suffix2 = img === "png";
        const suffix3 = img === "jpeg";
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (type == 1) {
          if (!suffix && !suffix2 && !suffix3) {
            this.$message.error("只能上传图片！");
            return false;
          }

          if (!isLt2M) {
            this.$message.error("上传头像图片大小不能超过 10MB!");
            return false;
          }
        }
        let userId = this.$store.state.imWorkbench.userId
        const uuid = crypto.randomUUID();
        const fileType = file.name.split('.').pop();
        this.uploadData.key = `${userId}-${uuid}.${fileType}`;
      },
      preview(e) {
        window.ipcRenderer
          .invoke("win-operate", {
            win: "ChildWin",
            operate: "start",
            argu: {
              width: 800,
              height: 550,
              resizable: true,
              route: `/ImagePreview?src=${encodeURIComponent(e.url)}`,
            },
          })
          .then(() => {
            this.$message("预览失效");
          });
      },
      delAttachment(index) {
        this.ruleForm.attachment.splice(index, 1);
      },
      fangd(formName) {
        // 使用已经创建的防抖函数
        this.debouncedSubmit(formName);
      },


      getVal(item, key) {
        if (item.__config__.tagIcon === "upload") {
          if (typeof item.__config__[key] == 'string') {
            item.__config__[key] = JSON.parse(item.__config__[key])
          }
          let i = item.__config__[key].map(item => item.fileName).join(",")
          return i
        } else if (item.__config__.tagIcon === "checkbox" || item.__config__.tagIcon === "date-range" || item.__config__.tagIcon === "time-range") {
          return item.__config__[key] ? item.__config__[key].join(",") : ''
        } else if (item.__config__.tagIcon === "cascader") {
          if (!Array.isArray(item.__config__[key])) {
            item.__config__[key] = [item.__config__[key]]
          }
          return item.__config__[key].map((cascaderItem) => {
            const findItem = item.options.find((optionItem) => {
              return cascaderItem === optionItem.value
            })

            if (findItem) {
              return findItem.label
            }
          }).filter((mapItem) => {
            return mapItem !== undefined
          }).join(",")
        } else {
          return item.__config__[key] + ""
        }
      },
      getRecords(data) {
        return this.detailForm.fields.reduce((acc, item) => {

          if (item.__vModel__) {
            item.__config__.newVal = this.processInstance.formVariables[item.__vModel__]
            acc.push({
              processDefinitionId: this.processInstance.processDefinition.id,
              processInstanceId: this.processInstance.id,
              fieldId: item.__vModel__,
              fieldName: item.__config__.label,
              oldVal: this.getVal(item, "defaultValue"),
              newVal: this.getVal(item, "newVal"),
              type: item.__config__.type,
              tagIcon: item.__config__.tagIcon
            });
          } else {
            if (item.__config__.children.length > 0) {
              item.__config__.children.forEach((child) => {
                if (Array.isArray(child)) {
                  child.forEach((subChild) => {
                    subChild.__config__.newVal = this.processInstance.formVariables[subChild.__vModel__]
                    acc.push({
                      processDefinitionId: this.processInstance.processDefinition.id,
                      processInstanceId: this.processInstance.id,
                      fieldId: subChild.__vModel__,
                      fieldName: subChild.__config__.label,
                      oldVal: this.getVal(subChild, "defaultValue"),
                      newVal: this.getVal(subChild, "newVal"),
                      type: subChild.__config__.type,
                      tagIcon: subChild.__config__.tagIcon
                    });
                  });
                } else {
                  child.__config__.newVal = this.processInstance.formVariables[child.__vModel__]
                  acc.push({
                    processDefinitionId: this.processInstance.processDefinition.id,
                    processInstanceId: this.processInstance.id,
                    fieldId: child.__vModel__,
                    fieldName: child.__config__.label,
                    oldVal: this.getVal(child, "defaultValue"),
                    newVal: this.getVal(child, "newVal"),
                    type: child.__config__.type,
                    tagIcon: child.__config__.tagIcon
                  });
                }
              });
            }
          }
          return acc;
        }, [])
      },
      async handleAudit() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.loading = true;
        const params = {
          id: this.taskId,
          ...this.ruleForm,
          variables: this.processInstance.formVariables,
          records: this.getRecords()
        };
        params.reason = this.$refs.inputArea.textContent

        if (this.candidate_dept) {
          params.variables.candidate_dept = this.candidate_dept;
        }

        let moneyList = params.records.filter(e => e.type == "amountMoney")
        if (moneyList.length > 0) {
          moneyList.forEach(key => {
            if (params.variables.hasOwnProperty(key.fieldId)) {
              params.variables[key.fieldId] = parseFloat(params.variables[key.fieldId]);
            }
          });
        }
        let nodesInfo = JSON.parse(JSON.stringify(this.nodesInfo))
        if (Object.keys(nodesInfo).length > 0) {
          for (let key in nodesInfo) {
            if (params.variables.hasOwnProperty(key)) {
              params.variables[key] = nodesInfo[key];
            }
          }
        }


        // let digestsList = params.records.filter(e => e.tagIcon == 'input')
        // let digest = []
        // if (digestsList.length > 0) {
        //   digest = digestsList.map(e => {
        //     return {
        //       name: e.fieldName,
        //       value: e.newVal,
        //     }
        //   })
        // }

        let digest = []
        let digestsList = []
        if (this.digestIf == 1) {
          this.digestInfo.forEach(e => {
            this.formFields.forEach(i => {
              if (e.__vModel__ == i.__vModel__) {
                let value = switchDigest(i, 1, params.variables)
                if (value) {
                  digest.push({ name: i.__config__.label, value })
                }
              }
            })
          })
        } else {
          digestsList = this.formFields.filter(e => e.__vModel__).slice(0, 3)
          digestsList.forEach(e => {
            let value = switchDigest(e, 1, params.variables)
            if (value) {
              digest.push({ name: e.__config__.label, value })
            }
          })
        }
        params.digest = JSON.stringify(digest);

        let fullTextList = []
        //递归全局搜索
        this.confFields.forEach(i => {
          if (i.__config__.tag === "el-row") {
            i.__config__.children.forEach(j => {
              if (j.length > 0) {
                j.forEach(e => {
                  let value = switchDigest(e, 0, params.variables)
                  fullTextList.push(e.__config__.label)
                  fullTextList.push(value)
                })
              }
            })
          } else {
            let value = switchDigest(i, 0, params.variables)
            fullTextList.push(i.__config__.label)
            fullTextList.push(value)
          }
        })
        params.fullText = fullTextList.join(' ');
        try {
          const { data, code, msg } = this.pass
            ? await approveTask(params)
            : await rejectTask(params);
          this.loading = false;
          if (this.isNotice) {
            let body = {
              title: this.processInstance.name,
              content: this.$refs.inputArea.textContent,
              processInstanceId: this.processInstance.id,
              operator: JSON.parse(localStorage.getItem("key_user_info")).userName,
              viewType: this.pass ? 1 : 0
            }
            let userIds = this.atList.map(o => o.userId)
            userIds.push(this.processInstance.startUser.userId)
            let re = await flowUrge({ userIds, msgType: 16, body })
            this.isNotice = false
          }
          this.atList = []
          this.dialogVisible = false;
          loading.close();
          this.$message({
            message: "审批成功！",
            type: 'success',
            duration: 2000,
            onClose: () => {
              this.$emit("auditSucceed");
            }
          })
        } catch (error) {
          loading.close();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .file {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;

    >div {
      padding: 4px 10px;
      border: 1px solid #dddfe5;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .picture_list {
    display: flex;
    gap: 12px;
    margin-bottom: 10px;

    >div {
      position: relative;
    }

    .del_box {
      position: absolute;
      right: -6px;
      top: -6px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: red;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .attachment_list {
    display: flex;
    flex-direction: column;

    >div {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      gap: 8px;
      height: 50px;
      border-bottom: 1px solid #c9c9c9;

      div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .required-ioc {
    color: red;
    position: absolute;
    top: -8px;
    left: -10px;
  }

  .tag_list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  ::v-deep .evaluate-dialog {
    background: rgba(250, 251, 252, 1);

    .el-dialog__header {
      display: flex;
      padding: 20px 20px 10px;

      .el-dialog__headerbtn {
        top: 8px;
        right: 4px;
      }
    }

    .el-dialog__body {
      padding: 10px 20px 0px 20px;

      .el-textarea__inner {
        border: 0px;
        min-height: 200px !important;
      }

      .tag {
        text-align: left;
        margin-bottom: 20px;

        .title {
          margin-bottom: 12px;
          color: rgba(27, 27, 27, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
          line-height: 13px;
        }

        .tag_reply {
          display: flex;
        }

        .tag_operate {
          display: flex;
          gap: 16px;
          margin-left: auto;

          img {
            width: 24px;
            height: 24px;
            margin-top: 2px;
          }
        }

        .tag_list {
          span {
            border-radius: 100px;
            background: rgba(86, 105, 236, 0.05);
            padding: 6px 16px;
            display: flex;
            color: rgb(86, 105, 236);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;
            align-items: center;
            border-color: rgb(242, 243, 251)
          }

          .active {
            color: rgb(86, 105, 236);
            background: rgba(86, 105, 236, 0.05);
          }

          .die {
            color: rgb(248, 62, 69);
            background: rgba(248, 62, 69, 0.05);
          }

          .tag_add {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            padding: 6px 16px;
            border-radius: 100px;

            img {
              width: 16px;
              height: 16px;
              margin-left: 8px;
            }

          }

        }
      }

      .file>div {
        width: 136px;
        height: 58px;
        border-radius: 8px;
        border: 1px dashed rgba(199, 199, 201, 1);
        box-sizing: border-box;
        background: rgba(250, 251, 252, 1);
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

    .el-dialog__footer {
      .dialog-footer {
        display: flex;
        justify-content: flex-end;

        .cancel {
          width: 84px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(243, 244, 246, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(132, 132, 132, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }

        .submit {
          width: 84px;
          height: 36px;
          border-radius: 4px;
          background: rgba(86, 105, 236, 1);
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  .aaaaaa {
    .sss {
      color: rgba(86, 105, 236, 1);
      margin-right: 8px;
    }
  }

  .inputArea {
    width: 100%;
    height: 140px;
    border: 1px solid rgba(86, 105, 236, 1);
    border-radius: 4px;
    text-align: left;
    padding: 0 10px;
    overflow-y: auto;
    line-height: 18px;
    box-sizing: border-box;
  }
</style>