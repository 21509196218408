import request from "@/utils/request";

export function getTodoTaskPage(data) {
  return request({
    url: "/bpm/task/todo-page",
    method: "post",
    params: data,
  });
}

export function getDoneTaskPage(query) {
  return request({
    url: "/bpm/task/done-page",
    method: "get",
    params: query,
  });
}

export function getTransmitPage(query) {
  return request({
    url: "/bpm/common/record",
    method: "get",
    params: query,
  });
}

export function getLogList(query) {
  return request({
    url: "/bpm/history/list",
    method: "get",
    params: query,
  });
}

export function completeTask(data) {
  return request({
    url: "/bpm/task/complete",
    method: "PUT",
    data: data,
  });
}

export function approveTask(data) {
  return request({
    url: "/bpm/task/approve",
    method: "PUT",
    data: data,
  });
}

export function rejectTask(data) {
  return request({
    url: "/bpm/task/reject",
    method: "PUT",
    data: data,
  });
}
export function backTask(data) {
  return request({
    url: "/bpm/task/back",
    method: "PUT",
    data: data,
  });
}

export function updateTaskAssignee(data) {
  return request({
    url: "/bpm/task/update-assignee",
    method: "PUT",
    data: data,
  });
}

export function getTaskListByProcessInstanceId(processInstanceId) {
  return request({
    url:
      "/bpm/task/list-by-process-instance-id?processInstanceId=" +
      processInstanceId,
    method: "get",
  });
}
export function getReturnList(taskId) {
  return request({
    url: "/bpm/task/get-return-list?taskId=" + taskId,
    method: "get",
  });
}

export function returnTask(data) {
  return request({
    url: "/bpm/task/return",
    method: "PUT",
    data: data,
  });
}
export function delegateTask(data) {
  return request({
    url: "/bpm/task/delegate",
    method: "PUT",
    data: data,
  });
}

// 通过任务
export function simpleTaskApprove(data) {
  return request({
    url: "/bpm/simple/task/approve",
    method: "PUT",
    data,
  });
}
export function simpleTaskReject(data) {
  return request({
    url: "/bpm/simple/task/reject",
    method: "PUT",
    data,
  });
}
// 任务审批进度
export function simpleApproveInfo(instanceId) {
  return request({
    url: "/bpm/simple/task/approveInfo?instanceId=" + instanceId,
    method: "get",
  });
}
//获取待办任务分页(人事)
export function getTodoPage(query) {
  return request({
    url: "/bpm/simple/task/todo-page",
    method: "get",
    params: query,
  });
}
//获取已办任务分页(人事)
export function getDonePage(query) {
  return request({
    url: "/bpm/simple/task/done-page",
    method: "get",
    params: query,
  });
}

//获取我收到的列表
export function getReceivedPage(params) {
  return request({
    url: "/bpm/process-instance/my-received",
    method: "get",
    params,
  });
}

// 企业微信催办
export function pressFlow(data) {
  return request({
    url: "/bpm/process-instance/pressFlow",
    method: "post",
    data,
  });
}

// 所见催办
export function sendFlowUrge(data) {
  return request({
    url: "/chat/capi/chat/msg/flowUrge",
    method: "post",
    data,
  });
}

export function getMapping(instanceId, taskDefKey) {
  return request({
    url: `/bpm/common/share/${instanceId}?taskDefKey=${taskDefKey}`,
    method: "get",
  });
}

export function flowUrge(data) {
  return request({
    url: `/chat/capi/chat/msg/flowUrge`,
    method: "post",
    data,
  });
}
export function getUsersByDeptIds(data) {
  return request({
    url: `system/user/getUsersByDeptIds`,
    method: "post",
    data,
  });
}