<template>
  <main>
    <el-empty :description="tip" v-if="!tasks.length"
      image='https://source.fungsong.com/3452097265742FYF1wx7cNHae.png'></el-empty>
    <template v-else>
      <div class="item" v-for="(item, index) in tasks" :key="index">
        <div class="left">
          <div style="display: flex;">
            <img class="user_img" :src="getTaskImg(item)" alt="">
          </div>

          <div class="line" :style="{
            borderLeft: `2px  solid  ${ getBgColor(item) }`,
          }" v-if="index != tasks.length - 1"></div>
        </div>
        <div class="right">
          <div class="descriptions" style="align-items: center;margin-bottom: 10px;">
            <div class="title ">{{ item.result != 9999 ? item.name : '发起了审批' }}</div>
            <div v-if="item.taskType == 'COMMENT_TASK'" class="descriptions-label">添加了评论</div>
            <div v-if="item.taskType == 'SERVICE_TASK'" class="descriptions-label">抄送人</div>
            <div v-if="item.approveType" class="descriptions-label">{{keytype[item.approveType]}}</div>
            <div v-if="item.assigneeUsers.length > 1" class="descriptions-img"
              @click="showSupplement(item.id || item.taskDefKey)">
              <img :src="getIcon(revoFlag[item.id || item.taskDefKey])" alt="">
            </div>
          </div>
          <!-- 审批人 -->
          <div class="descriptions" v-if="item.assigneeUsers && revoFlag[item.id || item.taskDefKey]">
            <template v-if="item.optionList && item.optionList.length">
              <optionalUser v-if="item.assigneeUsers.length == 0" :taskdata="item.optionList[0]" :users="users"
                :taskDefKey="item.taskDefKey" @chooseUser="chooseUser" />
            </template>
            <div :class="item.taskType === 'SERVICE_TASK' ? 'value_list_ccCopy' : 'value_list'"
              :ref="`featureRef`+index">
              <div class="scrollBarHidden value" v-for="(sub, i) in item.assigneeUsers" :key="i"
                :style="{'margin-top': i>0? '10px' : ''}">
                <img v-if="sub.avatarUrl" :src="sub.avatarUrl"
                  :style="{'filter':(sub.deptId == null && item.taskType === 'SERVICE_TASK') ? 'brightness(0.6)' : '','margin-right' :  item.taskType == 'COMMENT_TASK' ? '20px' : '0px'}"
                  alt="cc" />
                <img v-else src="@/assets/ioc/Group_41.png" alt="aa"
                  :style="{'filter':(sub.deptId == null && item.taskType === 'SERVICE_TASK') ? 'brightness(0.6)' : '','margin-right' :  item.taskType == 'COMMENT_TASK' ? '20px' : '0px'}" />
                <img v-if="hideImg(item,sub) && item.taskType != 'COMMENT_TASK'" class="hide_img"
                  :src="getTaskStatusImg(item)" alt="bb" />
                <p style="white-space: nowrap;color:rgb(27, 27, 27)" class="time"
                  :style="{ 'margin-left': (isHide(item) =='fail' || isHide(item) =='normal') ? '-14px' : '8px',color: sub.deptId == null ? 'rgba(27, 27, 27, 0.4)' : 'rgba(27, 27, 27, 1)' }">
                  {{ handleNodeNull(sub)}}
                </p>

                <div class="quit" v-if="sub.deptId == null">
                  离职
                </div>

                <el-tooltip class="item" effect="dark" content="节点审批人为空，已转交管理员" placement="top-start"
                  v-if="sub.userName.indexOf('(节点审批人为空，已转交管理员)') != -1">
                  <i class="el-icon-warning"></i>
                </el-tooltip>

                <div class=" supplement" v-if="item.taskType != 'SERVICE_TASK'">
                  <div v-if="item.result && item.taskType != 'COMMENT_TASK'" style="margin-right: 10px;"> {{
                    keytype[item.result] }}</div>

                  <div v-if="item.endTime">
                    {{ formattedTime(item.endTime) }}
                  </div>
                  <div v-else-if="item.createTime">
                    {{ formattedTime(item.createTime) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 状态 -->
          <!-- <div class="descriptions" v-if="item.result == 10">
            <div class="label">状态</div>
            <div class="value">已转交</div>
          </div> -->
          <!-- 耗时 -->
          <div class="descriptions" v-if="item.result == 1">
            <div class="timeDisplay">{{ diffTime(item.createTime) }}</div>
            <div class="label">已等待</div>
          </div>
          <!-- 备注 -->
          <div :class="item.taskType == 'COMMENT_TASK' ? 'reason ongoing':'reason'"
            style="display: flex;align-items: center;justify-content: space-between;"
            v-if="item.reason && item.result != 12">{{
            item.reason.indexOf('主动取消任务') > -1 ?
            `${tasks[0].assigneeUsers[0].userName}：${item.reason}` : item.reason }}

            <el-button type="text" @click="deleteComment(item)"
              v-if="item.taskType == 'COMMENT_TASK' && revocationFlag(item.createTime)"
              :class="fontColor">撤销</el-button>
          </div>
          <!-- 评论 -->
          <!-- v-if="item.comments && item.comments.length" -->
          <template v-if="item.taskType == 'COMMENT_TASK'">
            <div class="reason" :class="fontColor"
              v-if="item.result == 2 && (item.picture.length > 0 || item.attachment.length > 0)">
              <div style="margin-bottom: 8px">
                <!-- <div class="name">
                  <p>{{ e.userName }}:</p>
                  <template v-if="userId == e.userId">
                    <el-button type="text" @click="deleteComment(e)" v-if="revocationFlag(e.createTime)"
                      :class="fontColor">撤销</el-button>
                  </template>
                </div>
                <p>{{ e.comment }}</p> -->
                <div class="picture_list" v-if="item.picture && item.picture.length > 0">
                  <div v-for="(sub, p) in item.picture" :key="p" @contextmenu.prevent="showContextMenu(sub,$event)">
                    <img :src="sub.url" alt="" @click="preview(sub)" style="width: 50px; height: 50px;" />
                  </div>
                </div>
                <div class="attachment_list" v-if="item.attachment && item.attachment.length > 0">
                  <div v-for="(sub, p) in item.attachment" :key="p">
                    <div style="display: flex;align-items: center;gap: 10px;">
                      <i class="el-icon-document" style="font-size: 28px;"></i>
                      <p>{{ sub.name }}</p>
                    </div>
                    <div>
                      <el-link @click="preview(sub)" class="yulan">预览</el-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reason" :class="fontColor" v-if="item.result == 12">
              <div>已于 {{ formattedTime(item.endTime) }} 撤回评论</div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <!-- 文件预览 -->
    <FilePreviewDialog ref="FileDialog" :fileData="fileData" :width="'90%'" @closePreviewDialog="closePreviewDialog" />

    <div v-if="showMenu" class="menu_list" :style="{ top: address.top + 'px', left: address.left + 'px' }">
      <div class="menu_item" @click="copyImg">
        复制
      </div>
    </div>
  </main>
</template>

<script>
  import FilePreviewDialog from "./FilePreviewDialog.vue";
  import { listSimpleUsers } from "@/api/system/user";
  import optionalUser from "./optionalUser.vue";
  import { deleteComment } from "@/api/bpm/flow";
  import { getDate } from "@/utils/dateUtils";
  import { parseTime } from "@/utils/ruoyi";
  export default {
    components: {
      optionalUser,
      FilePreviewDialog,
    },
    props: ["tasks", "userId", "tip", 'type', 'processInstance'],
    data() {
      return {
        copyUrl: {},
        showMenu: false,
        address: {
          top: 0,
          left: 0
        },
        users: [],
        openFilePreview: false,
        // fileUrl: null, // 预览本地文件，文件放置与public文件夹下
        // fileName: null, //
        // 预览网络文件
        fileData: {
          name: '',
          url: '',
        },
        keytype: {
          'sequence': '依次审批',
          'and': '会签',
          'or': '或签',
          '1': '处理中',
          '2': '通过',
          '3': '不通过',
          '4': '已取消',
          '5': '退回',
          '6': '委派',
          '7': '待后加签任务完成',
          '8': '待前加签任务完成',
          '9': '待前置任务完成',
          '10': '转办',
          '11': '退回至发起人节点',
          '9999': '发起',
        },
        // 按钮判断
        revoFlag: {},
      };
    },
    watch: {
      tasks(val) {
        this.bianlist();
      },
    },
    computed: {
      fontColor() {
        switch (this.processInstance.status) {
          case 1:
            return "ongoing"; //进行中
            break;
          case 2:
            if (this.processInstance.result == 2) {
              return "pass";//已通过
            } else {
              return "fail";//未通过
            }
            break;
          case 3:
            return "revocation";//已撤销
            break;
        }
      }
    },
    created() {
      this.listSimpleUsers();
      document.addEventListener('click', () => {
        this.showMenu = false;
      });
      console.log(this.tasks, 'tasks')
    },
    methods: {
      // 遍历判断是否能隐藏
      bianlist() {
        this.tasks.forEach((item, index) => {
          if (item.assigneeUsers) {
            this.$set(this.revoFlag, [item.id || item.taskDefKey], true);
          }
        });
      },

      // 返回图标
      getIcon(is) {
        if (is) return require(`@/assets/ioc/angle-down-circle.png`);
        return require(`@/assets/ioc/angle-down-ci.png`);
      },

      // 收起节点
      showSupplement(value) {
        this.revoFlag[value] = !this.revoFlag[value];
      },

      // 返回节点图片
      getTaskImg(row) {
        if (row.taskType == 'COMMENT_TASK') {
          return require(`@/assets/ioc/Group_1x.png`);
        }
        switch (this.isHide(row)) {
          case 'fail':
            return require(`@/assets/ioc/Group_1000005649.png`);
            break;
          case 'abnormal':
            return require(`@/assets/ioc/Group_1000005637.png`);
            break;
          default:
            return require(`@/assets/ioc/Group_1000005636.png`);
            break;
        }
      },

      // 返回节点状态图标
      getTaskStatusImg(row) {
        if (this.isHide(row) == 'fail') {
          return require(`@/assets/ioc/exclircle.png`);
        }
        if (this.isHide(row) == 'normal') {
          return require(`@/assets/ioc/check-circle.png`);
        }
        return ''
      },

      // 判断是否隐藏
      isHide(row) {
        const ID = row.result
        if (ID == 3) {
          return 'fail'
        }
        if (ID == 2 || ID == 4 || ID == 5 || ID == 6 || ID == 7 || ID == 9 || ID == 10 || ID == 11 || ID == 12 || ID == 9999) {
          return 'normal'
        } else {
          return 'abnormal'
        }
      },

      formattedTime(time) {
        return parseTime(time);
      },

      preview(e) {
        if (window.ipcRenderer) {
          window.ipcRenderer
            .invoke("win-operate", {
              win: "ChildWin",
              operate: "start",
              argu: {
                width: 800,
                height: 550,
                resizable: true,
                route: `/ImagePreview?src=${encodeURIComponent(e.url)}&name=${encodeURIComponent(e.name)}`,
              },
            })
            .then(() => {
              this.$message("预览失效");
            });
        } else {
          this.fileData = e;
          this.$refs.FileDialog.dialogVisible = true;
        }
      },

      closePreviewDialog() {
        this.$refs.FileDialog.dialogVisible = !this.$refs.FileDialog.dialogVisible;
      },

      chooseUser(e) {
        this.$emit("chooseUser", e);
      },

      async listSimpleUsers() {
        const { data } = await listSimpleUsers();
        this.users = data;
      },

      getApproveType(e) {
        if (!e) return;
        let arr = [
          {
            label: "顺签",
            value: "sequence",
          },
          {
            label: "会签",
            value: "and",
          },
          {
            label: "或签",
            value: "or",
          },
        ];
        return arr.find((item) => item.value == e).label;
      },

      async deleteComment(e) {
        let flag = this.revocationFlag(e.createTime);
        if (flag) {
          const { data } = await deleteComment({ commentId: e.id });
          this.$emit("deleteComment");
        } else {
          this.$message("无法撤销");
          this.$emit("deleteComment");
        }
      },

      revocationFlag(e) {
        if (!e) return false;
        let min = 2 * 60 * 1000;
        let createTime = e + min;
        let newTime = new Date().getTime();
        return createTime < newTime ? false : true;
      },

      getDateStar(ms) {
        return getDate(ms);
      },

      diffTime(val) {
        const now = new Date().getTime();
        const future = new Date(val);
        const diff = now - future;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
          return `${days}天`;
        } else if (hours > 0) {
          return `${hours}小时`;
        } else if (minutes > 0) {
          return `${minutes}分钟`;
        } else {
          return `${seconds}秒`;
        }

      },

      // 处理节点为空和节点人员离职
      handleNodeNull(sub) {
        if (sub.userName.indexOf("(节点审批人为空，已转交管理员)") != -1) {
          return sub.userName.substring(0, sub.userName.indexOf("(节点审批人为空，已转交管理员)"))
        }
        if (sub.deptId == null) {
          return sub.userName.replace('(已离职)', '');
        }
        return sub.userName
      },

      // 判断展示图标
      hideImg(item, sub) {
        if (item.taskType == 'SERVICE_TASK') {
          if (sub.isRead == null) {
            return false
          }
          return sub.isRead === 0 ? false : true
        } else {
          return this.isHide(item) == 'fail' || this.isHide(item) == 'normal'
        }
      },

      // 右键菜单
      showContextMenu(sub, e) {
        this.copyUrl = sub;
        this.showMenu = true;
        this.address = {
          top: e.layerY,
          left: e.layerX,
        };
      },

      async copyImg() {
        try {
          // 1. 获取图片数据
          const response = await fetch(this.copyUrl.url); // 获取图片 Blob 数据
          let blob = await response.blob(); // 转换为 Blob 对象

          // 2. 判断图片格式，如果不是 PNG，则转换为 PNG
          if (blob.type !== 'image/png') {
            blob = await this.convertToPng(blob); // 调用转换函数
          }

          // 3. 创建剪贴板项
          const clipboardItem = new ClipboardItem({
            [blob.type]: blob, // 将 Blob 数据设置到剪贴板项中
          });

          // 4. 写入剪贴板
          await navigator.clipboard.write([clipboardItem]);

          // 5. 提示复制成功
          this.$message({
            message: '图片复制成功',
            type: 'success',
          });
        } catch (error) {
          // 错误提示
          console.error('复制图片失败:', error);
          this.$message({
            message: '图片复制失败',
            type: 'error',
          });
        }

        // 6. 关闭菜单
        this.showMenu = false;
      },

      // 图片格式转换函数（将其他格式转换为 PNG）
      async convertToPng(blob) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          const reader = new FileReader();

          reader.onload = function () {
            img.src = reader.result; // 将 Blob 转为 base64 URL
          };
          img.onload = function () {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0); // 绘制图像到 Canvas 上

            // 将 Canvas 转为 PNG Blob
            canvas.toBlob(resolve, 'image/png'); // 转换为 PNG 格式
          };
          img.onerror = reject;
          reader.onerror = reject;

          reader.readAsDataURL(blob); // 读取 Blob 数据
        });
      },

      // 路线颜色
      getBgColor(item) {
        const status = this.isHide(item);
        if (status == 'fail') {
          return "rgb(255, 82, 25);";
        }
        if (status == 'normal') {
          return "rgba(86, 105, 236)";
        }
        if (status == 'abnormal') {
          return "rgb(228, 229, 231);";
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  main {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .item {
      display: flex;
      gap: 8px;

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;


        .user_icon {
          width: 24px;
          height: 24px;
          border: 1px solid rgb(86, 105, 236);
          border-radius: 50%;
          box-sizing: content-box;

          .user_img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }

          .user_img_active {
            position: relative;
            top: -15px;
            left: 10px;
          }
        }


        .dot {
          width: 12px;
          flex: 0 0 12px;
          border-radius: 50%;
          background: "#060000";
        }

        .end_dot {
          width: 12px;
          flex: 0 0 12px;
          border: 1px solid #060000;
          box-sizing: border-box;
          border-radius: 50%;
        }

        .line {
          width: 0;
          flex: 1;
          border-left: 1px dashed #c7c7c9;
        }
      }

      .right {
        width: 90%;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        margin-top: 2px;

        .time {
          color: rgba(132, 132, 132, 0.5);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
          text-align: left;
        }

        .descriptions {
          display: flex;
          text-align: left;

          /* gap: 10px; */


          .timeDisplay {
            color: rgb(86, 105, 236);
            font-size: 11px;
          }

          .label {
            color: rgb(132, 132, 132);
            font-size: 11px;
            display: flex;
            align-items: center;
            margin-left: 4px;
          }

          .title {
            color: rgb(27, 27, 27);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 14px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          /* 正常审批 */
          .value_list {
            width: 100%;

            .value {
              width: 100%;
              /* height: 20px; */
              color: #727272;
              display: flex;
              align-items: center;
              gap: 4px;
              /* margin-bottom: 14px; */

              p {
                flex: 0 0 42px;
              }

              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }

              .hide_img {
                width: 16px !important;
                height: 16px !important;
                position: relative;
                top: -12px;
                left: -18px;
              }

              .quit {
                width: 28px;
                height: 16px;
                border-radius: 2px;
                background: rgba(132, 132, 132, 0.16);

                color: rgba(132, 132, 132, 1);
                font-family: "Noto Sans SC";
                font-weight: 400;
                font-size: 10px;

                text-align: center;
                line-height: 16px;
                margin-left: 2px;
              }
            }
          }

          /* 抄送人 */
          .value_list_ccCopy {
            display: flex;
            flex-wrap: wrap;
            row-gap: 6px;
            column-gap: 28px;

            .value {
              margin-top: 10px;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              width: 40px;

              p {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 42px;
              }

              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }

              .hide_img {
                width: 16px !important;
                height: 16px !important;
                position: absolute;
                top: -2px;
                left: 28px;
              }

              .time {
                color: rgba(132, 132, 132, 0.5);
                font-family: "Noto Sans SC";
                font-weight: 400;
                font-size: 14px;
                text-align: left;
                text-align: center;
                margin-left: 0px !important
              }

              .quit {
                position: absolute;
                color: var(--温柔灰, rgba(228, 229, 231, 1));
                font-family: "Noto Sans SC";
                font-weight: 400;
                font-size: 12px;
                top: 12px;
              }
            }
          }
        }

        .reason {
          width: 100%;
          background: rgba(243, 244, 246, 1);
          border-radius: 0px 6px 6px 6px;
          padding: 8px 12px;
          color: rgba(132, 132, 132, 1);
          text-align: left;
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 11px;
          box-sizing: border-box;
          user-select: text;

          .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
          }
        }
      }
    }
  }


  .descriptions-label {
    border-radius: 2px;
    border: 1px solid var(--主题色, rgba(86, 105, 236, 1));
    box-sizing: border-box;
    color: var(--主题色, rgba(86, 105, 236, 1));
    font-size: 10px;
    padding: 2px;
    margin-left: 10px;
    white-space: nowrap;
  }

  .picture_list {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;

    div {
      cursor: pointer;
    }
  }

  .attachment_list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    >div {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
    }

    .yulan {
      color: rgb(86, 105, 236);
      font-size: 11px;
      font-family: "Noto Sans SC";
      font-weight: 400;
    }
  }

  .scrollBarHidden {
    /* max-width: 180px; */
    /* margin-bottom: 8px; */

    /* 或者 overflow: scroll; */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .scrollBarHidden::-webkit-scrollbar {
    width: 0;
    /* 或者 height: 0; */
    background: transparent;
    /* 使滚动条透明 */
  }

  .user_img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }


  .hide_select {
    border: 1px solid rgba(86, 105, 236, 1)
  }

  .supplement {
    /* white-space: nowrap; */
    margin-left: auto;
    color: rgb(132, 132, 132);
    font-family: "Noto Sans SC";
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    display: flex;
  }

  .descriptions-img {
    margin-left: auto;
    display: flex;
  }

  /* 进行中 */
  .ongoing {
    background: rgb(226, 230, 248) !important;
    color: rgb(86, 105, 236) !important;
    font-size: 14px !important;
  }

  /* 已通过 */
  .pass {
    background: #E0EBEC !important;
    color: #3A9B91 !important;
    font-size: 14px !important;
  }

  /* 未通过 */
  .fail {
    background: #F4E5E6 !important;
    color: #FA6155 !important;
    font-size: 14px !important;
  }

  /* 已撤销 */
  .revocation {
    background: rgba(185, 185, 185, 0.15) !important;
    color: rgba(132, 132, 132, 1) !important;
    font-size: 14px !important;
  }

  .menu_list {
    background-color: #ffffff;
    position: absolute;
    width: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #000000;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0px 0px 10px 0px rgb(61 59 59 / 75%);

    .menu_item {
      text-align: center;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .menu_item:hover {
      background-color: #f2f2f2;
    }

  }
</style>